import { FC, ReactElement } from 'react'
import clsx from 'clsx'

import style from './toggle-button.module.scss'

interface IToggleButton extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  isActive?: boolean
  isAccented?: boolean
  modificator?: string
  withPlus?: boolean
  children?: string | ReactElement
}

const ToggleButton: FC<IToggleButton> = ({
  isActive,
  isAccented,
  withPlus,
  modificator,
  children,
  ...props
}: IToggleButton) => (
  <button
    className={clsx(style.toggleButton, {
      [style.toggleButtonActive]: isActive,
      [style.toggleButtonAccented]: isAccented,
      [modificator as string]: modificator,
    })}
    {...props}
  >
    {!isActive && <p className={style.toggleButtonText}>{children}</p>}
    {withPlus && (
      <div className={clsx(style.toggleButtonPlus, 'toggleButtonPlus')}>
        <div
          className={clsx(style.toggleButtonPlusLine, {
            [style.lineAccented]: isAccented,
            [style.toggleButtonPlusLine1Active]: isActive,
          })}
        />
        <div
          className={clsx(style.toggleButtonPlusLine, style.toggleButtonPlusLine2, {
            [style.lineAccented]: isAccented,
            [style.toggleButtonPlusLine2Active]: isActive,
          })}
        />
      </div>
    )}
  </button>
)
export default ToggleButton
