//TODO может, два массива строк везде передавать?

const defineSelectedForCard = (Options: string[], selectedOptions?: any[]) => {
  if (!Options?.length) return
  if (selectedOptions && selectedOptions?.length) {
    const selectedLabels = selectedOptions.map((option) => option.label || option.name)
    const found = selectedLabels.find((item) => Options.includes(item))
    return found || Options[0]
  }
  if (Options && Options?.length) return Options[0]
  return ''
}

export { defineSelectedForCard }
