import clsx from 'clsx'
import { FC, useEffect, useState } from 'react'

import { ILectur } from 'constants/types/common.types'
import { BASE_URL } from 'constants/url'
import { getAxiosSingle } from 'pages/api/axios'
import {
  AVATAR_HALF_WIDTH,
  cutLecturs,
  getAllLectorsCount,
  getFullName,
  getLectorsIds,
} from './utils'
import { getLectorPreview } from 'components/cards/lector-card/utils'

import styles from './lectors.module.scss'

interface ILectors {
  Lecturs: ILectur[]
  lecturName?: string
  isDark?: boolean
  bgColor?: string
}

const Lectors: FC<ILectors> = ({
  Lecturs,
  lecturName,
  isDark = false,
  bgColor = '#E1DBCB',
}: ILectors) => {
  const [lectors, setLectors] = useState<ILectur[] | null>(null)
  const lectursCount = getAllLectorsCount(lectors, lecturName)

  useEffect(() => {
    const lectorsIds = getLectorsIds(Lecturs)
    const getData = async () => {
      const allInfoLectors = lectorsIds.map((id) => getAxiosSingle(`${BASE_URL.LECTUR}/${id}`))
      Promise.all(allInfoLectors).then((results) => {
        const data = results.filter((result) => {
          if (result.status) {
            console.log(`Не удалось получить аватарку лектора`)
            return false
          }
          return true
        })

        const cuttedLecturs = cutLecturs(data, lecturName)

        if (cuttedLecturs) {
          setLectors(cuttedLecturs)
        }
      })
    }
    getData()
  }, [])

  if (!lectors) return null

  return (
    <>
      <div
        className={styles.avatarWrapper}
        style={{ width: `${(lectors?.length + 1) * AVATAR_HALF_WIDTH}px` }}
      >
        {lectors?.map((lectur, idx) => {
          const { previewName, firstName, lastName } = lectur
          const leftShift = AVATAR_HALF_WIDTH * (lectors.length - 1 - idx)
          return (
            <div
              key={lectur.id}
              className={styles.lecturWrapper}
              style={{ left: `${leftShift}px` }}
            >
              {getLectorPreview(lastName, firstName, previewName, styles.avatar, bgColor)}
            </div>
          )
        })}
      </div>
      {Boolean(lectors.length) && (
        <div
          className={clsx(styles.author, {
            [styles.authorDark]: isDark,
          })}
        >
          {getFullName(lectors[lectors.length - 1])}
          {Boolean(lectursCount > 1) && <> и еще {lectursCount - 1}</>}
        </div>
      )}
    </>
  )
}

export default Lectors
