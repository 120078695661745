import Image from 'next/legacy/image'
import clsx from 'clsx'

import { STATIC_PREVIEW_URL, currImageDomain } from 'constants/data'
import { generateLectorTumbnail } from 'utils/genereateLectorTumbnail'

import styles from './lector-card.module.scss'

const getNameToRender = (toBeShort: boolean, newName: (string | undefined)[]) => {
  let nameToRender

  if (toBeShort) nameToRender = newName.join(' ')
  else {
    nameToRender = newName
      .filter((item) => item)
      .map((el) => {
        return <span key={el}>{el}&nbsp;</span>
      })
  }
  return nameToRender
}

const getLectorPreview = (
  firstName?: string,
  lastName?: string,
  previewName?: string,
  modificator?: string,
  backgroundColor?: string,
  borderColor?: string
) =>
  previewName ? (
    <div
      className={clsx(styles.cardImage, styles.noText, styles.cardImageSmall, modificator)}
      style={{ background: backgroundColor, border: `2px solid ${borderColor}` }}
    >
      <Image
        src={`${currImageDomain}${STATIC_PREVIEW_URL}/lectur/${previewName}`}
        width="238"
        height="238"
        alt="lector avatar"
        unoptimized={true}
      />
    </div>
  ) : (
    <div className={clsx(styles.cardImage, styles.cardImageSmall, modificator)}>
      {firstName && lastName && generateLectorTumbnail(undefined, `${firstName} ${lastName}`)}
    </div>
  )

export { getNameToRender, getLectorPreview }
