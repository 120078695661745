import { FC } from 'react'

import CardsWebinars from '../cards-webinars/cards-webinars'
import WidthLimiter from 'containers/width-limiter/width-limiter'
import SwiperProgress from 'components/parts/swiper-progress/swiper-progress'
import IntroSpacer from 'components/intro-alt/intro-spacer/intro-spacer'

import { IWebinarCard2 } from 'constants/types/card.types'

import styles from './nearest-webinars.module.scss'

interface INearestWebinars {
  webinars: IWebinarCard2[]
}

const NearestWebinars: FC<INearestWebinars> = ({ webinars }) => {
  return (
    <WidthLimiter noBottomPadding noTopPadding>
      <IntroSpacer />
      <h2 className={styles.title}>Ближайшие вебинары</h2>

      <SwiperProgress id="1">
        {webinars.map((item, index) => {
          return (
            <CardsWebinars
              {...item}
              key={index}
              index={index}
              isLoaded={true}
              activeDots={index}
              isFullInfo
            />
          )
        })}
      </SwiperProgress>
    </WidthLimiter>
  )
}

export default NearestWebinars
