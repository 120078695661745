import { STATIC_URL } from 'constants/data'

const imgPaths = {
  mobile: {
    src: `${STATIC_URL}/intro-alt/doctor-mobile.png`,
    width: 375,
    height: 356,
    alt: 'Доктор',
  },
  desktop: {
    src: `${STATIC_URL}/intro-alt/doctor-desktop.png`,
    width: 233,
    height: 238,
    alt: 'Доктор',
  },
}

export { imgPaths }
