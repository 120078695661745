import { ILectur } from '../constants/types/common.types'
import { STATIC_URL } from '../constants/data'

const imgPath = `${STATIC_URL}/lector-card/medpoint_logo_min.svg`

const generateLectorTumbnail = (Lecturs?: ILectur[], lecturName?: string) => {
  if (Lecturs && Lecturs.length) {
    const firstLetter = Lecturs[0]?.firstName ? Lecturs[0]?.firstName.slice(0, 1) : ''
    const lastLetter = Lecturs[0]?.lastName ? Lecturs[0]?.lastName.slice(0, 1) : ''
    return firstLetter + lastLetter
  }
  if (lecturName) {
    if (lecturName?.includes('Medpoint')) {
      return (
        <img
          src={imgPath}
          width="100%"
          height="100%"
          alt="medpoint avatar"
          decoding="async"
          loading="lazy"
        />
      )
    }

    if (lecturName.length) {
      const nameSplitted = lecturName.split(' ')
      return nameSplitted
        .map((item: string) => item?.slice(0, 1))
        ?.slice(0, 2)
        ?.join('')
    }
  }
}

export { generateLectorTumbnail }
