import { URL } from './SEO'

const MEDPOINT = 'Medpoint'

interface IBreadCrumb {
  id: string
  name: string
}
type TBreadCrumbs = IBreadCrumb[]

const MainPageBC: TBreadCrumbs = [
  {
    id: `${URL}`,
    name: `Главная`,
  },
]

const AboutPageBC: TBreadCrumbs = [
  ...MainPageBC,
  {
    id: `${URL}/about`,
    name: `О ${MEDPOINT}`,
  },
]

const PartnersPageBC: TBreadCrumbs = [
  ...MainPageBC,
  {
    id: `${URL}/partners`,
    name: `Партнеры`,
  },
]

const WebinarPageBC: TBreadCrumbs = [
  ...MainPageBC,
  {
    id: `${URL}/webinar`,
    name: `Вебинары`,
  },
]

const WebinarPastBC: TBreadCrumbs = [
  ...MainPageBC,
  {
    id: `${URL}/webinar/past`,
    name: `Вебинары для врачей в записи`,
  },
]

const WebinarVideoprojectBC: TBreadCrumbs = [
  ...MainPageBC,
  {
    id: `${URL}/webinar/videoprojects`,
    name: `видеопроекты`,
  },
]

const LibraryPageArticleBC: TBreadCrumbs = [
  ...MainPageBC,
  {
    id: `${URL}/library/article`,
    name: `Медицинские статьи`,
  },
]

const LibraryPageVideoBC: TBreadCrumbs = [
  ...MainPageBC,
  {
    id: `${URL}/library/video`,
    name: `Медицинские лекции и видео`,
  },
]

const LibraryPageClinicBC: TBreadCrumbs = [
  ...MainPageBC,
  {
    id: `${URL}/library/clinic`,
    name: `Клинические рекомендации`,
  },
]

const LibraryPagePodcastBC: TBreadCrumbs = [
  ...MainPageBC,
  {
    id: `${URL}/library/podcast`,
    name: `Подкасты по медицине для врачей`,
  },
]

const ProfilePageBC: TBreadCrumbs = [
  ...MainPageBC,
  {
    id: `${URL}/profile`,
    name: `Личный кабинет`,
  },
]

const LecturePageBC: TBreadCrumbs = [
  ...MainPageBC,
  {
    id: `${URL}/lecture`,
    name: `Лекторы`,
  },
]

const NMOPageBC: TBreadCrumbs = [
  ...MainPageBC,
  {
    id: `${URL}/nmo`,
    name: `Баллы НМО`,
  },
]

const NMOActivationBC: TBreadCrumbs = [
  ...NMOPageBC,
  {
    id: `${URL}/nmo/activation`,
    name: `Как добавить баллы НМО`,
  },
]

const NMOAccreditationBC: TBreadCrumbs = [
  ...NMOPageBC,
  {
    id: `${URL}/nmo/accreditation`,
    name: `Как получить налоговый вычет за курсы НМО врачу?`,
  },
]

const NMOQualificationBC: TBreadCrumbs = [
  ...NMOPageBC,
  {
    id: `${URL}/nmo/qualification`,
    name: `Инструкцуия про способы оплаты курсов для повышения квалификации`,
  },
]

const NMORequestBC: TBreadCrumbs = [
  ...NMOPageBC,
  {
    id: `${URL}/nmo/request`,
    name: `Как подать заявку на портале НМО на обучение`,
  },
]

const NMOReportBC: TBreadCrumbs = [
  ...NMOPageBC,
  {
    id: `${URL}/nmo/report`,
    name: `Отчет о профессиональной деятельности`,
  },
]

const PaymentPageBC: TBreadCrumbs = [
  ...MainPageBC,
  {
    id: `${URL}/payment`,
    name: `Оплата`,
  },
]

const SearchPageBC: TBreadCrumbs = [
  ...MainPageBC,
  {
    id: `${URL}/search`,
    name: `Поиск на Medpoint`,
  },
]

const ClinicalCasePageBC: TBreadCrumbs = [
  ...MainPageBC,
  {
    id: `${URL}/clinical-case`,
    name: `Клинические случаи`,
  },
]

const CoursePageBC: TBreadCrumbs = [
  ...MainPageBC,
  {
    id: `${URL}/program`,
    name: `Курсы НМО`,
  },
]

const AboutOrganizationPageBC: TBreadCrumbs = [
  ...MainPageBC,
  {
    id: `${URL}/about-organization`,
    name: `Сведения об образовательной организации`,
  },
]

const GamePageBC: TBreadCrumbs = [
  ...MainPageBC,
  {
    id: `${URL}/game`,
    name: `Бонусная программа Medpoint`,
  },
]

const GamePromoBC: TBreadCrumbs = [
  ...MainPageBC,
  {
    id: `${URL}/game/promo`,
    name: `Бонусная программа Medpoint`,
  },
]

const GameHistoryBC: TBreadCrumbs = [
  ...GamePageBC,
  {
    id: `${URL}/game/history`,
    name: `История начислений Медкоинов`,
  },
]

const ArticleBC = (articleData: IBreadCrumb): TBreadCrumbs => [
  ...LibraryPageArticleBC,
  {
    id: `${URL}/article/${articleData.id}`,
    name: articleData.name,
  },
]

const ClinicalCaseBC = (id: string): TBreadCrumbs => [
  ...ClinicalCasePageBC,
  {
    id: `${URL}/clinical-case/${id}`,
    name: `Клинический случай №${id}`,
  },
]

const LectorBC = (lectorData: IBreadCrumb): TBreadCrumbs => [
  ...LecturePageBC,
  {
    id: `${URL}/lecture/${lectorData.id}`,
    name: lectorData.name,
  },
]

const CourseBC = (courseData: IBreadCrumb): TBreadCrumbs => [
  ...CoursePageBC,
  {
    id: `${URL}/program/${courseData.id}`,
    name: courseData.name,
  },
]

const WebinarBC = (webinarData: IBreadCrumb): TBreadCrumbs => [
  ...WebinarPageBC,
  {
    id: `${webinarData.id}`,
    name: webinarData.name,
  },
]

const PodcastBC = (podcastData: IBreadCrumb): TBreadCrumbs => [
  ...LibraryPagePodcastBC,
  {
    id: `${podcastData.id}`,
    name: podcastData.name,
  },
]

const WebinarTargetBC = (webinarData: IBreadCrumb): TBreadCrumbs => [
  ...WebinarPageBC,
  {
    id: `${URL}/webinar/target/${webinarData.id}`,
    name: webinarData.name,
  },
]

const ArticleTargetBC = (articleData: IBreadCrumb): TBreadCrumbs => [
  ...WebinarPageBC,
  {
    id: `${URL}/library/article/target/${articleData.id}`,
    name: articleData.name,
  },
]

const VideoTargetBC = (videoData: IBreadCrumb): TBreadCrumbs => [
  ...WebinarPageBC,
  {
    id: `${URL}/library/video/target/${videoData.id}`,
    name: videoData.name,
  },
]
const CorporateBC: TBreadCrumbs = [
  ...GamePageBC,
  {
    id: `${URL}/corporate`,
    name: `Корпоративное обучение врачей с баллами НМО`,
  },
]

export {
  MainPageBC,
  AboutPageBC,
  PartnersPageBC,
  WebinarPageBC,
  WebinarPastBC,
  WebinarVideoprojectBC,
  LibraryPageArticleBC,
  LibraryPageVideoBC,
  LibraryPageClinicBC,
  LibraryPagePodcastBC,
  ProfilePageBC,
  LecturePageBC,
  NMOPageBC,
  NMOAccreditationBC,
  NMOActivationBC,
  NMOReportBC,
  NMOQualificationBC,
  NMORequestBC,
  PaymentPageBC,
  SearchPageBC,
  ClinicalCasePageBC,
  CoursePageBC,
  CorporateBC,
  AboutOrganizationPageBC,
  ArticleBC,
  ClinicalCaseBC,
  LectorBC,
  CourseBC,
  WebinarBC,
  PodcastBC,
  GamePageBC,
  GamePromoBC,
  GameHistoryBC,
  WebinarTargetBC,
  ArticleTargetBC,
  VideoTargetBC,
}
export type { TBreadCrumbs }
