import { RefObject, ReactNode, useState, FC, useRef, useContext, useEffect } from 'react'
import { useDebounce } from 'use-debounce'

import statisticContext from 'context/statisticsContext'

import A from 'utils/metrics/analytic'
import { IStringObj } from 'store/data-types'
import { Events, SocketEvents } from 'constants/events'

const PERCENT_DIVIDER = 25

interface IScrollEventWrap {
  containerRef: RefObject<HTMLDivElement>
  event: string
  children: ReactNode
  resetParameter?: any
  additionalParameters?: IStringObj
}

const ScrollEventWrap: FC<IScrollEventWrap> = ({
  containerRef,
  event,
  children,
  resetParameter,
  additionalParameters,
}: IScrollEventWrap) => {
  const socket = useContext(statisticContext)
  const [destinationLeft, setDestinationLeft] = useState(0)
  const [distance] = useDebounce(destinationLeft, 500)
  const percentRef = useRef(0)
  const percentMultipler = useRef(0)
  const initSendRefSended = useRef(false)
  const reloadContentRef = useRef(false)

  const height = containerRef?.current?.offsetHeight || 0

  const sendEvent = (percent: number) => {
    percentRef.current = percent
    socket?.sendEvent(SocketEvents.ARTICLE_READ, { progress: percent })
    const calculatedMultiplier = Math.floor(percent / PERCENT_DIVIDER)

    if (calculatedMultiplier > percentMultipler.current || !initSendRefSended.current) {
      percentMultipler.current = calculatedMultiplier
      initSendRefSended.current = true
      A.setEvent(event, {
        progress: String(calculatedMultiplier * PERCENT_DIVIDER),
        ...additionalParameters,
      })
    }
  }

  useEffect(() => {
    const element = containerRef?.current
    if (element) {
      const windowScroll = () => {
        const leftToBottom = element.getBoundingClientRect().bottom
        setDestinationLeft(leftToBottom)
      }

      window.addEventListener(Events.SCROLL, windowScroll)
      return () => window.removeEventListener(Events.SCROLL, windowScroll)
    }
  }, [])

  useEffect(() => {
    if (socket?.sendEvent && distance) {
      const distanceToBottom = distance - window.innerHeight
      const progress = Math.round((100 * (height - distanceToBottom)) / height)
      const shapedPercent = Math.max(progress, 0)
      const percent = Math.min(shapedPercent, 100)
      if (percent > percentRef.current && reloadContentRef.current) {
        sendEvent(percent)
      } else {
        reloadContentRef.current = true
      }
    }
  }, [distance])

  useEffect(() => {
    setDestinationLeft(0)
    percentRef.current = 0
    percentMultipler.current = 0
    reloadContentRef.current = false
  }, [resetParameter])

  useEffect(() => {
    reloadContentRef.current = true
  }, [socket?.sendEvent])

  return <>{children}</>
}

export default ScrollEventWrap
