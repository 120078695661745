import { useRouter } from 'next/router'

import WidthLimiter from 'containers/width-limiter/width-limiter'
import Button from '../../parts/button/button'
import CrowdHelp from 'components/crowd-funding/crowd-help/crowd-help'

import A from 'utils/metrics/analytic'
import { A_REGISTRATION_CLICK_BUTTON2 } from 'constants/analytic-data'

import styles from './intro-medic.module.scss'

const IntroMedic = () => {
  const router = useRouter()

  const buttonClickHandler = async () => {
    A.setEvent(A_REGISTRATION_CLICK_BUTTON2)
    router.push(`/#registration`)
  }
  return (
    <WidthLimiter noBottomPadding noTopPadding>
      <div className={styles.title}>Поддерживаем медиков в учебе и жизни</div>
      <div className={styles.subtitle}>
        Medpoint присоединяется к фонду «ВБлагодарность» для помощи медикам, оказавшимся в трудной
        жизненной ситуации. Теперь мы ежемесячно будем переводить фонду сумму, равную количеству
        часов, проведенных каждым из вас за просмотром вебинаров.
      </div>
      <CrowdHelp isLink={false} />
      <div className={styles.btn} onClick={buttonClickHandler}>
        <Button>Стать частью сообщества</Button>
      </div>
    </WidthLimiter>
  )
}

export default IntroMedic
