import Link from 'next/link'

import WidthLimiter from 'containers/width-limiter/width-limiter'

import { STATIC_URL } from 'constants/data'

import styles from './info-link.module.scss'

const imgPath = {
  arrow: `${STATIC_URL}/intro-alt/arrow-link.png`,
  card1: `${STATIC_URL}/intro-alt/card-1-bg.png`,
  card2: `${STATIC_URL}/intro-alt/card-2-bg.png`,
  card3: `${STATIC_URL}/intro-alt/card-3-bg.png`,
}

const dataTxt = [
  {
    title: '57 896',
    subtitle: 'Врачей уже смотрят вебинары вместе с нами',
    class: styles.card1,
    bg: imgPath.card1,
    link: '/webinar',
  },
  {
    title: '5+',
    subtitle: 'Курсов НМО по всем специальностям',
    class: styles.card2,
    bg: imgPath.card2,
    link: '/program',
  },
  {
    title: '500+',
    subtitle: 'Практикующих <br /> лекторов',
    class: styles.card3,
    bg: imgPath.card3,
    link: '/lecture',
  },
]

const InfoLink = () => {
  return (
    <WidthLimiter noBottomPadding noTopPadding>
      <div className={styles.cardsWrap}>
        {dataTxt.map((item, index) => {
          return (
            <Link
              href={item.link}
              className={item.class}
              key={index}
              style={{ backgroundImage: `url(${item.bg})` }}
            >
              <div className={styles.cardTitle}>{item.title}</div>
              <div
                className={styles.cardSubtitle}
                dangerouslySetInnerHTML={{ __html: item.subtitle }}
              ></div>

              <div
                className={styles.cardLink}
                style={{ backgroundImage: `url(${imgPath.arrow})` }}
              ></div>
            </Link>
          )
        })}
      </div>
    </WidthLimiter>
  )
}

export default InfoLink
