import { STATIC_URL } from 'constants/data'

const imgPath = {
  imgWebinar1: `${STATIC_URL}/intro-alt/nearest_1.jpg`,
  imgWebinar2: `${STATIC_URL}/intro-alt/nearest_2.jpg`,
  imgWebinar3: `${STATIC_URL}/intro-alt/nearest_3.jpg`,
  imgWebinar4: `${STATIC_URL}/intro-alt/nearest_4.jpg`,
}

const imgData = [
  {
    src: imgPath.imgWebinar1,
    alt: 'картинка 1 вебинара',
    width: 600,
    height: 325,
  },
  {
    src: imgPath.imgWebinar2,
    alt: 'картинка 2 вебинара',
    width: 600,
    height: 325,
  },
  {
    src: imgPath.imgWebinar3,
    alt: 'картинка 3 вебинара',
    width: 600,
    height: 325,
  },
  {
    src: imgPath.imgWebinar4,
    alt: 'картинка 4 вебинара',
    width: 600,
    height: 325,
  },
  {
    src: imgPath.imgWebinar1,
    alt: 'картинка 5 вебинара',
    width: 600,
    height: 325,
  },
]

export { imgData }
